import APIService from "./api.service";

class GlobalParamsService {

    async getInfosPratiques() {
        let url = '/global_params/infos_pratiques';
        return await APIService.get(url);
    }

    async getInscriptionBenevoles() {
        let url = '/global_params/inscription_benevoles';
        return await APIService.get(url);
    }

    async getConcoursCosplay() {
        let url = '/global_params/inscription_cosplay';
        return await APIService.get(url);
    }

    async getConcoursCreateur() {
        let url = '/global_params/inscription_concours_createurs';
        return await APIService.get(url);
    }

    async getInscriptionExposants() {
        let url = '/global_params/inscription_exposants';
        return await APIService.get(url);
    }
}

export default new GlobalParamsService();