<template>
  <v-container :fluid="!$vuetify.breakpoint.mobile" class="ma-0 pa-0">
      <v-row no-gutters>
          <v-col md="8" cols="12" offset-md="2">
              <Tarifs/>
          </v-col>
      </v-row>
    <Reservation/>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Reservation from '@/components/Reservation.vue'
import Tarifs from "../components/Infos-utiles/Tarifs";

export default {
  name: 'Billeterie',
  components: {
    Tarifs,
    Reservation
  }
}
</script>
<style scoped lang="scss">
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
h2 {
  font-family: $font-titre;
  font-weight: bold;
  color: black;
}

.v-card__title {
  font-family: $font-titre;
  font-weight: bold;
  justify-content: center;
  color: ghostwhite;
  background-color: $bleu;
}

.v-card__text {
  font-family: $font;
  background-color: #eeeeee;

span {
  font-weight: bold;
}
}
}
</style>