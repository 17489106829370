<template>
  <v-row justify="center">
    <v-col cols="12" class="text-center">
      <div class="section">
        <h2>Les Tarifs</h2>
      </div>
      <div class="section">
        <p class="paraph">
          "Un seul bracelet pour trois jours, un seul bracelet pour les faire
          jouer à tout. Un seul bracelet pour au Ludinam les amener et les faire
          revenir les années suivantes.” –
          <span>La communauté de Ludinam, Ludinam</span>
        </p>
        <v-row class="conteneur" no-gutters>
          <v-col cols="12" md="6" class="text-center" v-for="tarif in tarifs">
            <v-card outlined>
              <v-card-title>
                {{ tarif.title }}
              </v-card-title>
              <v-card-text class="tarif-card pa-2" v-html="tarif.text">
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <p class="paraph mt-5">Ludinam c’est donc un bracelet à acheter, qui permet ensuite d'accéder à toutes les animations
          proposées par le festival et ce durant trois jours.
          Car mettre le jeu, tous les jeux, à portée de tous et de toutes les bourses, tel est l’objectif du festival
          Ludinam.</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import GlobalParamsService from "../../services/global_params.service";

export default {
  name: "Tarifs",
  data: () => ({
    tarifs: [],
  }),
  created() {
    GlobalParamsService.getInfosPratiques().then(response=>{
      this.tarifs = response['data'].value.tarifs;
    });
  }
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }

  .paraph {
    font-family: $font;

    span {
      font-style: italic;
    }
  }

  .conteneur {
    display: flex;
    flex-direction: row;

    .v-card__title {
      font-family: $font-titre;
      font-weight: bold;
      justify-content: center;
      color: ghostwhite;
      background-color: $bleu;
    }

    .v-card__text {
      font-family: $font;
      background-color: #eeeeee;

      span {
        font-weight: bold;
      }
    }

    .v-card__text.tarif-card {
      height: 100%;

      span.tarif {
        font-size: 30px;
      }

      span.txt {
        color: $orange;
      }
    }
  }
}
</style>
